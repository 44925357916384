import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import {
  generateDisplayedMonths,
  injectTotalHoursAndFlexToMonths,
  isVacationCode,
  isFloatingHolidayCode,
} from './lib/util'
import { addYears, endOfYear, getDate, getISOWeek, getYear, getDaysInMonth } from 'date-fns'

import { formatISO, formatISOMonth, formatMonth, today, getWorkWeekISODaysOfWeek } from './lib/date'

import styles from './css/Calendar.module.css'

const isHoliday = (publicHolidays, day, dayIndexInWeek) =>
  publicHolidays[day] || dayIndexInWeek === 5 || dayIndexInWeek === 6
const weekYearAndIsoWeek = (week) => `${getYear(week.days[6] || week.days[0])}-${week.isoWeek}`
const formatSumBasedOnSumMode = (sumMode) => (number) =>
  sumMode === 'flex' && number > 0 ? `+${number}` : number.toString()

const getComputedHeightOfClass = (className) => {
  const el = document.createElement('div')
  el.classList.add(className)
  document.documentElement.appendChild(el)
  const height = el.getBoundingClientRect().height
  document.documentElement.removeChild(el)
  return height
}

const arrayEquals = (a, b) => {
  if (a.length !== b.length) return false
  return a.every((item, index) => item === b[index])
}

const MONTH_PADDING = 10
const MONTH_ROW_HEIGHT = getComputedHeightOfClass(styles.monthRow)
const WEEK_ROW_HEIGHT = getComputedHeightOfClass(styles.weekRow)

const Calendar = ({
  publicHolidays,
  locale,
  months,
  vacations,
  floatingHolidays,
  innerRef,
  sumMode,
  onDayClicked,
  daysWithErrors,
}) => {
  const scrollingContainerRef = useRef()
  const [visibleMonths, setVisibleMonths] = useState([0])
  // Used for diffing the montsh to avoid calling `setVisibleMonths` each scroll event
  const visibleMonthsRef = useRef([0])

  const now = new Date()
  const thisWeek = getYear(now) + '-' + getISOWeek(now)
  const errors = (daysWithErrors || []).map((d) => formatISO(d))

  const [monthsWithPositions, monthsTotalHeight] = useMemo(() => {
    const result = []

    let offset = MONTH_PADDING
    for (const month of months) {
      const monthHeight = MONTH_PADDING + MONTH_ROW_HEIGHT + WEEK_ROW_HEIGHT * month.weeks.length
      result.push({
        ...month,
        verticalPosition: offset,
        height: monthHeight,
      })
      offset += monthHeight
    }

    return [result, offset]
  }, [months])

  // List virtualization. This allows only the visible months to be rendered instead of all.
  // People, who have been in the company for long, might have over a hundred months in their
  // calendars. Rendering them all at once would require rendering tens of thousands of elements.
  // This allows us to only show a minimal amount of months at a time.
  useEffect(() => {
    const scrollingContainer = scrollingContainerRef.current
    if (!scrollingContainer) return

    const handleScroll = () => {
      let firstVisibleMonthIndex = monthsWithPositions.findLastIndex(
        (month) => month.verticalPosition <= scrollingContainer.scrollTop
      )
      firstVisibleMonthIndex = firstVisibleMonthIndex === -1 ? 0 : firstVisibleMonthIndex

      const visibleIndices = [firstVisibleMonthIndex]

      // Calculate which additional months to display in order to fill the scrolling container
      const scrollingContainerHeight = scrollingContainer.getBoundingClientRect().height
      let totalHeightOfVisibleMonths = 0
      for (let index = firstVisibleMonthIndex + 1; index < monthsWithPositions.length; index++) {
        visibleIndices.push(index)
        totalHeightOfVisibleMonths += monthsWithPositions[index].height
        if (totalHeightOfVisibleMonths > scrollingContainerHeight) {
          break
        }
      }

      if (!arrayEquals(visibleMonthsRef.current, visibleIndices)) {
        visibleMonthsRef.current = visibleIndices
        setVisibleMonths(visibleIndices)
      }
    }

    scrollingContainer.addEventListener('scroll', handleScroll)
    return () => scrollingContainer.removeEventListener('scroll', handleScroll)
  }, [scrollingContainerRef, monthsWithPositions])

  useImperativeHandle(
    innerRef,
    () => ({
      scrollToDay(isoDay) {
        const month = formatISOMonth(isoDay)
        const scrollPercentage = getDate(isoDay) / getDaysInMonth(isoDay)

        this.scrollToMonth({ month, scrollPercentage })
      },
      scrollToMonth({ month, monthScrollPercentage = 0.5 }) {
        const monthWithPosition = monthsWithPositions.find((m) => m.month === month)
        const monthPosition = monthWithPosition.verticalPosition + monthWithPosition.height * monthScrollPercentage
        const centerOffset = -scrollingContainerRef.current.clientHeight * 0.4
        scrollingContainerRef.current.scrollTop = monthPosition + centerOffset
      },
      getScrollContainerRef: () => scrollingContainerRef,
    }),
    [monthsWithPositions]
  )

  const renderedMonths = visibleMonths.map((index) => monthsWithPositions[index])

  return (
    <div className={styles.monthsContainer} ref={scrollingContainerRef} data-test='calendar'>
      <div className={styles.monthsWrapper} style={{ height: monthsTotalHeight + 'px' }}>
        {renderedMonths.map(({ month, weeks, [sumMode]: monthlySum, verticalPosition }) => (
          <div
            key={month}
            className={styles.monthContainer}
            data-month={month}
            style={{ top: verticalPosition + 'px' }}
          >
            <div className={styles.monthRow}>
              <span className={styles.month}>
                {formatMonth(locale)(month)} {getYear(month)}
              </span>
              <span data-test='calendar-monthlyHours' className={styles.monthlyHours}>
                {formatSumBasedOnSumMode(sumMode)(monthlySum)} h
              </span>
            </div>
            {weeks.map((week) => (
              <div
                key={weekYearAndIsoWeek(week)}
                className={classnames(styles.weekRow, { [styles.currentWeek]: weekYearAndIsoWeek(week) === thisWeek })}
                data-iso-week={weekYearAndIsoWeek(week)}
              >
                <span className={styles.weekNumber}>{week.isoWeek}</span>
                {week.days.map((day, dayIndexInWeek) => {
                  const isSingleDayVacation =
                    !vacations[week.days[dayIndexInWeek - 1]] &&
                    !vacations[week.days[dayIndexInWeek + 1]] &&
                    vacations[day]
                  const isStartOfVacation =
                    !vacations[week.days[dayIndexInWeek - 1]] &&
                    vacations[day] &&
                    vacations[week.days[dayIndexInWeek + 1]]
                  const isMidOfVacation =
                    vacations[week.days[dayIndexInWeek - 1]] &&
                    vacations[day] &&
                    vacations[week.days[dayIndexInWeek + 1]]
                  const isEndOfVacation =
                    vacations[week.days[dayIndexInWeek - 1]] &&
                    vacations[day] &&
                    !vacations[week.days[dayIndexInWeek + 1]]
                  const isSingleDayFloatingHoliday =
                    !vacations[day] &&
                    !floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                    !floatingHolidays[week.days[dayIndexInWeek + 1]] &&
                    floatingHolidays[day]
                  const isStartOfFloatingHoliday =
                    !vacations[day] &&
                    !floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                    floatingHolidays[day] &&
                    floatingHolidays[week.days[dayIndexInWeek + 1]]
                  const isMidOfFloatingHoliday =
                    !vacations[day] &&
                    floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                    floatingHolidays[day] &&
                    floatingHolidays[week.days[dayIndexInWeek + 1]]
                  const isEndOfFloatingHoliday =
                    !vacations[day] &&
                    floatingHolidays[week.days[dayIndexInWeek - 1]] &&
                    floatingHolidays[day] &&
                    !floatingHolidays[week.days[dayIndexInWeek + 1]]
                  const hasErrors = errors.includes(day)

                  return (
                    <div
                      data-test='calendar-day'
                      className={classnames(styles.day, {
                        [styles.error]: errors.includes(day),
                        [styles.today]: day === formatISO(now),
                        [styles.holiday]: isHoliday(publicHolidays, day, dayIndexInWeek),
                      })}
                      key={day || dayIndexInWeek}
                      title={publicHolidays[day]}
                      onClick={onDayClicked.bind(null, day)}
                    >
                      <span>{day && getDate(day)}</span>
                      {!hasErrors && day === formatISO(now) && <div className={styles.todayCircle} />}
                      {hasErrors && <div className={styles.errorCircle} data-test='error-circle' />}
                      {isSingleDayVacation && <div className={styles.vacationCircle} data-test='vacation-circle' />}
                      {isStartOfVacation && (
                        <div className={styles.vacationCircleStart} data-test='vacation-circle-start' />
                      )}
                      {isMidOfVacation && <div className={styles.vacationMidSquare} data-test='vacation-circle-mid' />}
                      {isEndOfVacation && <div className={styles.vacationCircleEnd} data-test='vacation-circle-end' />}
                      {isSingleDayFloatingHoliday && (
                        <div className={styles.floatingHolidayCircle} data-test='floating-holiday-circle' />
                      )}
                      {isStartOfFloatingHoliday && (
                        <div className={styles.floatingHolidayCircleStart} data-test='floating-holiday-circle-start' />
                      )}
                      {isMidOfFloatingHoliday && (
                        <div className={styles.floatingHolidayMidSquare} data-test='floating-holiday-circle-mid' />
                      )}
                      {isEndOfFloatingHoliday && (
                        <div className={styles.floatingHolidayCircleEnd} data-test='floating-holiday-circle-end' />
                      )}
                    </div>
                  )
                })}
                <span
                  className={classnames(styles.weeklyHours, {
                    [styles.positiveFlex]: sumMode === 'flex' && week[sumMode] > 0,
                    [styles.zeroFlex]: sumMode === 'flex' && week[sumMode] === 0,
                    [styles.negativeFlex]: sumMode === 'flex' && week[sumMode] < 0,
                  })}
                >
                  {formatSumBasedOnSumMode(sumMode)(week[sumMode])} h
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

const CalendarWrapper = (
  {
    publicHolidays,
    serverDays,
    daysWithErrors,
    contracts,
    startDate,
    locale,
    onDayClicked,
    userExpectedHours,
    sumMode,
  },
  ref
) => {
  const currentContract = contracts.find((c) => c.start <= today() && (!c.finish || c.finish >= today()))
  const country = (currentContract && currentContract.company && currentContract.company.country) || 'FI'
  const endDate = endOfYear(addYears(today(), 1))
  const months = useMemo(() => generateDisplayedMonths(startDate, endDate, country), [startDate, endDate, country])
  const monthsWithWorkHours = useMemo(
    () => injectTotalHoursAndFlexToMonths(months, serverDays, userExpectedHours),
    [months, serverDays, userExpectedHours]
  )

  const vacations = useMemo(() => {
    const result = {}

    for (const [key, day] of Object.entries(serverDays)) {
      const isVacation = day.entries.some((entry) => isVacationCode(entry.hourCode))
      if (isVacation) {
        result[key] = true
      }
    }

    return result
  }, [serverDays])

  const floatingHolidays = useMemo(() => {
    const result = {}

    for (const [key, day] of Object.entries(serverDays)) {
      const isFloatingHoliday = day.entries.some((entry) => isFloatingHolidayCode(entry.hourCode))
      if (isFloatingHoliday) {
        result[key] = true
      }
    }

    return result
  }, [serverDays])

  // scroll the calendar to today() after first render
  useEffect(() => {
    ref.current && ref.current.scrollToDay(today())
  }, [months, ref])

  return (
    !_.isEmpty(months) && (
      <Calendar
        {...{
          publicHolidays,
          workWeekIsoDaysOfWeek: getWorkWeekISODaysOfWeek(country),
          startDate,
          locale,
          months: monthsWithWorkHours,
          vacations,
          floatingHolidays,
          daysWithErrors,
          sumMode,
          onDayClicked,
          innerRef: ref,
        }}
      />
    )
  )
}

export default React.memo(React.forwardRef(CalendarWrapper))
